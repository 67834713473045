<template>
  <div class="content-wrap">
    <div class="create-header select-content">
      <div class="header-bottom">
        <el-form ref="recordForm" :model="organization" label-width="140px">
          <el-row>
            <el-col :span="9">
              <el-form-item label="组织名称：" prop="name">
                <el-input :disabled="true" v-model.trim="organization.name" maxlength="128"/>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="组织税号：" prop="taxNo">
                <el-input :disabled="true" v-model.trim="organization.taxNo" maxlength="20"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="9">
              <el-form-item label="组织地址：" prop="address">
                <el-input :disabled="true" v-model="organization.address"/>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="组织电话：" prop="phone">
                <el-input :disabled="true" v-model="organization.phone"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="9">
              <el-form-item label="开户银行：" prop="bank">
                <el-input :disabled="true" v-model="organization.bank" maxlength="128"/>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="银行账号：" prop="account">
                <el-input :disabled="true" v-model="organization.account" maxlength="32"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form ref="declareSetting" :model="declareSetting" :rules="rules" label-width="140px">
          <el-row>
            <el-col :span="9">
              <el-form-item label="账套编号：" prop="ztbh">
                <el-input v-model="declareSetting.ztbh"/>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="账套名称：" prop="ztmc">
                <el-input v-model.trim="declareSetting.ztmc"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="9">
              <el-form-item label="纳税税种：">
                <span class="required">增值税/附加税</span>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="申报模板：" prop="declareTmplId">
                <el-select v-model="declareSetting.declareTmplId">
                  <el-option v-for="item in tntDeclareTmpl"
                             :key="item.id"
                             :label="item.tmplName"
                             :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="9">
              <el-form-item label="申报类型：" prop="declareMode">
                <el-select :formatter="declareModeFormat" v-model="declareSetting.declareMode"
                           @change="handleShowDeclareSetting">
                  <el-option v-for="item in declareModeList"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="计提周期：" prop="nsqxdm">
                <el-select :formatter="nsqxdmFormat" v-model="declareSetting.nsqxdm">
                  <el-option v-for="item in nsqxdmList"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <div v-if="selectExpended">
            <el-row>
              <el-col :span="9">
                <el-form-item label="登记注册类型：" prop="djzclx">
                  <el-input v-model="declareSetting.djzclx"/>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="注册资本：" prop="zczb">
                  <el-input v-model="declareSetting.zczb"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="9">
                <el-form-item label="开业时间：" prop="kysj">
                  <el-date-picker
                    v-model="declareSetting.kysj"
                    type="date"
                    value-format="yyyy-MM-dd"
                    :picker-options="dateOption"
                    placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="营业状态：" prop="yyzt">
                  <el-input v-model="declareSetting.yyzt"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="9">
                <el-form-item label="所属行业：" prop="sshy">
                  <el-input v-model="declareSetting.sshy"/>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="经营地址：" prop="jydz">
                  <el-input v-model="declareSetting.jydz"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="9">
                <el-form-item label="企业类型：" prop="qylx">
                  <el-input v-model="declareSetting.qylx"/>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="纳税人类型：" prop="zzsnsrlx">
                  <el-select :formatter="zzsnsrlxFormat" v-model="declareSetting.zzsnsrlx">
                    <el-option v-for="item in zzsnsrlxList"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>

                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-form-item label="适用会计准则：" prop="sykjzd">
                <el-radio-group v-model="declareSetting.sykjzd">
                  <el-radio v-for="item in sykjzdList" :key="item.value" :label="item.value">
                    {{item.label}}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-row>
            <el-row>
              <el-col :span="9">
                <el-form-item label="是否零申报：" prop="sflsb">
                  <el-select :formatter="sflsbFormat" v-model="declareSetting.sflsb">
                    <el-option v-for="item in sflsbList"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="操作代码：" prop="czlx">
                  <el-select :formatter="czlxFormat" v-model="declareSetting.czlx">
                    <el-option v-for="item in czlxList"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="9">
                <el-form-item label="企业账号密码：" prop="qymm">
                  <el-input v-model="declareSetting.qymm"/>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="企业 CA 密码：" prop="camm">
                  <el-input v-model="declareSetting.camm"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="9">
                <el-form-item label="申报种类编号：" prop="sbzlbh">
                  <!--                                <el-input v-model="declareSetting.sbzlbh"/>-->
                  <el-select :formatter="sbzlbhFormat" v-model="declareSetting.sbzlbh">
                    <el-option v-for="item in sbzlbhList"
                               :key="item.value"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="经办人联系电话：" prop="jbrlxdh">
                  <el-input v-model="declareSetting.jbrlxdh"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="9">
                <el-form-item label="经办人：" prop="jbr">
                  <el-input v-model="declareSetting.jbr"/>
                </el-form-item>
              </el-col>
              <el-col :span="9">
                <el-form-item label="实名制手机号：" prop="smdlsjh">
                  <el-input v-model="declareSetting.smdlsjh"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="9">
                <el-form-item label="经办人身份证号：" prop="jbrzfzh">
                  <el-input v-model="declareSetting.jbrzfzh"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="18">
                <el-form-item label="经营范围：" prop="jyfw">
                  <el-input type="textarea" v-model="declareSetting.jyfw" maxlength="100"
                            placeholder="经营范围"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="declareSetting.settingResult">
              <el-col :span="18">
                <el-form-item label="同步结果：" prop="settingResult">
                  <span style="color: red">{{declareSetting.settingResult}}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
      <div class="header-button">
        <div class="header-button-item">
          <el-button class="el-button-ext" @click="handleCancel" type="primary">返回</el-button>
          <el-button class="el-button-ext" v-loading="saveBtnLoading" @click="handleSave"
                     type="primary">保存
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getDeclareSetting, saveDeclareSetting, checkUniqueZtbh } from '@/service/declare/setting'
import { allTntTmplList } from '@/service/declare/index'
import { tableStyle, pagingMethods, dateOption, changeMethods } from '@/util/mixins'
import { selectOrganization } from '@/service/organization'
import { ReqOrganizationGetSelectList } from '@/service/organization'

export default {
  name: 'DecSbztXq',
  mixins: [tableStyle, dateOption, pagingMethods, changeMethods],
  data () {
    return {
      title: '创建组织',
      isEdit: false,
      disabled: true,
      sfyqdsfxyList: [],
      sykjzdList: [],
      nsqxdmList: [],
      declareModeList: [],
      zzsnsrlxList: [],
      sflsbList: [],
      czlxList: [],
      sbzlbhList: [],
      tntDeclareTmpl: [],
      selectExpended: false,
      organization: {
        id: undefined,
        name: undefined,
        taxNo: undefined,
        phone: undefined,
        address: undefined,
        contacts: undefined,
        bank: undefined,
        account: undefined,
        contactsWay: undefined
      },
      declareSetting: {
        id: undefined,
        orgId: undefined,
        sykjzd: undefined,
        czlx: undefined,
        qymm: undefined,
        camm: undefined,
        sbzlbh: undefined,
        nsqxdm: undefined,
        jbr: undefined,
        jbrlxdh: undefined,
        ztbh: undefined,
        ztmc: undefined,
        djzclx: undefined,
        zczb: undefined,
        kysj: undefined,
        yyzt: undefined,
        jydz: undefined,
        sshy: undefined,
        qylx: undefined,
        jyfw: undefined,
        declareTmplId: undefined,
        xzqhdm: undefined,
        declareMode: undefined,
        sfyqdsfxy: undefined,
        zzsnsrlx: undefined,
        sflsb: undefined,
        settingResult: undefined
      },
      saveBtnLoading: false,
      szRes: [],
      rules: {
        ztbh: [
          { required: true, message: '账套编号', trigger: 'blur' },
          { 'validator': this.handleCheckZtbh, trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        ztmc: [
          { required: true, message: '账套名称', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        declareTmplId: [
          { required: true, message: '请选择申报模板', trigger: 'blur' }
        ],
        declareMode: [
          { required: true, message: '请选择申报类型', trigger: 'blur' }
        ],
        nsqxdm: [
          { required: true, message: '请选择计提周期', trigger: 'blur' }
        ],
        qymm: [
          { required: true, message: '企业账号密码', trigger: 'blur' },
          { min: 0, max: 20, message: '长度在 0 到 20 个字符', trigger: 'blur' }
        ],
        camm: [
          { required: true, message: '企业 CA 密码', trigger: 'blur' },
          { min: 0, max: 20, message: '长度在 0 到 20 个字符', trigger: 'blur' }
        ],
        djzclx: [
          { required: true, message: '请输入登记注册类型', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        zczb: [
          { required: true, message: '请输入注册资本', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur' }
        ],
        kysj: [
          { required: true, message: '请选择开业时间', trigger: 'blur' }
        ],
        yyzt: [
          { required: true, message: '请输入营业状态', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        sshy: [
          { required: true, message: '请输入所属行业', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        jydz: [
          { required: true, message: '请输入经营地址', trigger: 'blur' },
          { min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur' }
        ],
        qylx: [
          { required: true, message: '请输入企业类型', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        zzsnsrlx: [
          { required: true, message: '请选择纳税人类型', trigger: 'blur' }
        ],
        sykjzd: [
          { required: true, message: '请选择适用会计准则', trigger: 'blur' }
        ],
        sflsb: [
          { required: true, message: '请选择是否零申报', trigger: 'blur' }
        ],
        czlx: [
          { required: true, message: '请选择操作代码', trigger: 'blur' }
        ],
        sbzlbh: [
          { required: true, message: '请选择申报种类编号', trigger: 'blur' }
        ],
        jbr: [
          { required: true, message: '请输入经办人', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        jbrlxdh: [
          { required: true, message: '请输入经办人联系电话', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        jbrzfzh: [
          { required: true, message: '请输入经办人身份证号', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        smdlsjh: [
          { required: true, message: '请输入实名制手机号', trigger: 'blur' },
          { min: 1, max: 11, message: '长度在 1 到 11 个字符', trigger: 'blur' }
        ],
        jyfw: [
          { required: true, message: '请输入经营范围', trigger: 'blur' },
          { min: 1, max: 500, message: '长度在 1 到 500 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.isEdit = this.$route.query.isEdit
    if (this.isEdit) {
      this.title = '编辑配置规则'
      let id = this.$route.query.id
      this.handleSelectOrganization(id)
      this.handleGetDeclareSetting(id)
    }
    this.getDicts('sfyqdsfxy').then(res => {
      this.sfyqdsfxyList = res.data
    })
    this.getDicts('sykjzd').then(res => {
      this.sykjzdList = res.data
    })
    this.getDicts('nsqxdm').then(res => {
      this.nsqxdmList = res.data
    })
    this.getDicts('dec_declare_mode').then(res => {
      this.declareModeList = res.data
    })
    this.getDicts('zzsnsrlx').then(res => {
      this.zzsnsrlxList = res.data
    })
    this.getDicts('sflsb').then(res => {
      this.sflsbList = res.data
    })
    this.getDicts('czlx').then(res => {
      this.czlxList = res.data
    })
    this.getDicts('sbzlbh').then(res => {
      this.sbzlbhList = res.data
    })

    this.initDict()
  },
  methods: {
    initDict () {
      //获取租户模板 筛选
      allTntTmplList().then(res => {
        this.tntDeclareTmpl = res.data
      })
    },
    /* 保存 */
    handleSave () {
      this.$refs.declareSetting.validate(valid => {
        if (!valid) {
          return
        } else {
          this.submitForm()
        }
      })
    },
    /* 提交表单 */
    async submitForm () {
      // loading保存按钮阻止反复提交
      this.saveBtnLoading = true
      this.declareSetting.orgId = this.organization.id
      const res = await saveDeclareSetting(this.declareSetting)
      this.saveBtnLoading = false
      this.szRes = res.data
      if (this.szRes.code == '0000') {
        this.toast(`${this.szRes.message}`, 'success', () => this.handleCancel('success'))
      } else {
        this.toast(`${this.szRes.message}`, 'error')
      }
    },
    /* 通知父组件关闭dialog */
    handleCancel (status) {
      this.$router.replace({ path: '/declare/setting/list' })
    },
    // 获取组织详情
    handleSelectOrganization (id) {
      selectOrganization(id).then(res => {
        if (res.success) {
          this.organization = res.data
        }
      })
    },
    //配置
    handleGetDeclareSetting (id) {
      getDeclareSetting(id).then(res => {
        if (res.success) {
          if (res.data != null) {
            this.declareSetting = res.data
            this.selectExpended = this.declareSetting.declareMode == 'ALONE'
          }
        }
      })
    },
    // 收起筛选列表
    handleShowDeclareSetting () {
      this.selectExpended = this.declareSetting.declareMode == 'ALONE'
    },
    // 账套编号校验
    handleCheckZtbh (rule, value, callback) {
      let param = {
        orgId: this.organization.id,
        ztbh: this.declareSetting.ztbh
      }
      checkUniqueZtbh(param).then(res => {
        if (res.success) {
          if (res.data) {
            callback()
          } else {
            callback(new Error('账套编号重复，请重新输入'))
          }
        }
      })
    },
    declareModeFormat (value) {
      return this.getDictLabel(this.declareModeList, value.declareMode)
    },
    zzsnsrlxFormat (value) {
      return this.getDictLabel(this.zzsnsrlxList, value.zzsnsrlx)
    },
    sflsbFormat (value) {
      return this.getDictLabel(this.sflsbList, value.sflsb)
    },
    czlxFormat (value) {
      return this.getDictLabel(this.czlxList, value.czlx)
    },
    nsqxdmFormat (value) {
      return this.getDictLabel(this.nsqxdmList, value.nsqxdm)
    },
    sbzlbhFormat (value) {
      return this.getDictLabel(this.sbzlbhList, value.sbzlbh)
    }
  }
}
</script>
<style lang="scss" scoped>
  .create-header {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 8px 0px rgba(153, 153, 153, 0.1);

    .header-bottom {
      margin-bottom: 24px;

      .el-row {
        margin: 24px 0px;

        &:last-child {
          padding-bottom: 20px;
        }
      }

      label {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 20px;
      }

      span {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 20px;
      }
    }

    .header-button {
      /*display: flex;*/
      /*flex-direction: column;*/
      text-align: center;
    }

  }

  .header-top {
    padding: 16px 24px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #e9e9e9;
    color: #333;

    span:before {
      content: "|";
      display: inline-block;
      font-size: 16px;
      margin-right: 10px;
      background-color: #333;
    }
  }

  .el-form-item__error {
    top: 30px;
  }
</style>
